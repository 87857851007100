import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import { Layout, Section } from '../components'
import { media } from '../styles/utils'
import { container, padding, colours, type, bgIcon, bgImage, buttonSmall, button } from '../styles/global'
import { parseACF, getImageCrop } from '../utils'
import { render } from 'react-dom'
import ProgressiveImage from 'react-progressive-image'
import {
    motion,
	transform,
} from 'framer-motion'

const Index = (props) => {
	const { x, y } = useWindowScroll();
	const { width, height } = useWindowSize()

	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')

	return (
		<Layout
             meta={data && data.seo}
        >
			<Container>
				<Section
					layout={'intro'}
					display
					image={data.hero_image}
					tag={data.hero_tagline}
					announcementDisplay={data.announcement_display}
					announcementText={data.announcement_text}
					announcementLink={data.announcement_button_link}
				/>

				<Section
					layout={'about'}
					display
					text={data.intro_text}
					image={data.intro_image}
				/>

				<Section
					layout={'what-we-offer'}
					display={data.what_we_offer_display}
					title="What We Offer"
					text={data.what_we_offer_text}
					classTypes={data.what_we_offer_class_types}
					image={data.what_we_offer_image}
				/>

				<Section
					layout={'locations'}
					title="Our Locations"
					display={data.locations_display}
					locations={data.locations_items}
				/>

				<Section
					layout={'join'}
					display={data.joining_display}
					classes={data.joining_classes}
					title={data.joining_heading}
					text={data.joining_text}
					link={data.joining_link}
					linkText={data.joining_link_text}
				/>

				<Section
					layout={'pricing'}
					display={data.pricing_display}
					title={data.pricing_heading}
					image={data.pricing_image}
					text={data.pricing_description}
					useLink={data.pricing_use_button}
					linkText={data.pricing_button_text}
					link={data.pricing_button_link}
				/>

				<Section
					layout={'discover'}
					display={data.discover_display}
					title="Discover more"
					slides={data.discover_pages}
				/>

				<Section
					layout={'community'}
					display={data.community_display}
					title={data.community_heading}
					image={data.community_image}
					text={data.community_description}
					useLink={data.community_use_social_links}
					links={data.community_social_links}
				/>

				<Section
					layout={'social'}
					display={data.follow_display}
					images={data.follow_images}
					text={data.follow_text}
				/>

			</Container>
		</Layout>	
	)
}

// Shared

// Utlity

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}
	flex-direction: column;
`

// Data

export const query = graphql`
     query {
         allWordpressInfopages(filter: { slug: { eq: "home" } }) {
             nodes {
                 acf_json
             }
         }
     }
`

export default Index